import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
import { Border } from "../../../components/widgets/container";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{` Similar Strategies `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-lev/"
            }}>{`All-Stars Leveraged`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-round-robin/"
            }}>{`Round-Robin`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-straight-four/"
            }}>{`Straight-Four`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-mach-1/"
            }}>{`Mach-1`}</a></li>
        </ul>
      </Aside>
      <Border mdxType="Border">
        <p>{`This strategy is 2x leveraged and `}<strong parentName="p">{`very risky`}</strong>{`. To dampen volatility, we suggest combining it with other strategies to a meta-portfolio. Our `}<a parentName="p" {...{
            "href": "/portfolios/tt-all-stars-lev/"
          }}>{`All-Stars Leveraged`}</a>{` does that for you.`}</p>
      </Border><br />
      <ul>
        <li parentName="ul">{`Objective: 2x leveraged growth`}</li>
        <li parentName="ul">{`Type: mean-variance optimization`}</li>
        <li parentName="ul">{`Invests in: leveraged ETFs tracking stocks, treasuries, gold`}</li>
        <li parentName="ul">{`Rebalancing schedule: weekly with possible daily exit`}</li>
        <li parentName="ul">{`Taxation: 100% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $5,000`}</li>
      </ul>
      <p>{`TuringTrader’s `}<em parentName="p">{`Mach-2`}</em>{` provides 2x leveraged returns, outperforming the S&P 500 in most years while limiting the downside risk. The proprietary strategy switches between separate models for bull-market and bear-market regimes. Both models use mean-variance optimization to select their assets and assign weights. `}<em parentName="p">{`Mach-2`}</em>{` rebalances weekly and holds no more than three ETFs, resulting in low maintenance effort. However, when markets transition from a bull to a bear-market regime, the strategy may require exiting positions any day. In summary, `}<em parentName="p">{`Mach-2`}</em>{` appeals to investors seeking to bolster the returns of a meta-portfolio with only little effort.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAE3klEQVQ4y2VUWWyUVRS+0iclvhgSeSAQjFAopWWVJeDGYgqiDxIMZakiRkz0wQVZhAiVLcGWgAoFElpppzNTpmWGLpS2086/3HPvv8zSzkwLEqG+woviE/2n95jzUwyJD+ffcu73f+d83zlMSsEcR7J79zKMc14kBDBNM5kQsBQAjgJAFAAMANABIAIA+6WEEsPglMMAoIjyEZFZlmA+WDptM9Pkkzj3k8oBoBsAkIJzjob5vygYJg/ejJszXFswW0LR72nB6upijI2ODj4L9jEAjBMIACjOuScEeBlXFCbCmwikGErKv1KOqEjagrk2FOm6yfwynwHDCbAxzrkSAlQ8Yarz4X51IdyvfgnF1c/BOJ5rjqufGvvGapv6sC9hYsoR69Ku8MunCwMB5ZzzcZNzNDkv0F03uEo7Qp1q6EX25m/I1jYi29CEbGNATXq/Wb28LYxLvmzzot06WoI/7B0wpgIQQ8NkAqDbloCOBWOO5d8VvQ8lJbZ0arh2XxQ/r+3CVz+5huv2RTGeMLF3wPRzpIAx27ZRCFEnpWTMkrCUGLX36Cp6S1fRbl1d79aR/hzp0rD1poaWAPxz2MZFX7Tilup2/CNn+a2htnAAFQyGUNO0R0NDmWlsJGMdbYwlkFU0eWxDk3ruvQAFsk0BfHFLEKduD2N924AvwpTKMG4+0o75tMSEZqDruiocbsGy8iWFUCiMd+6MVLGMK6LhDg2nf9TirfkuqmbuuobzPovg+VA/NsUSVJrPkMp8YXMQP/zxCaCmmwSIuz/do6bPmOXV1JzBfD53jkQxBQAdKtzNWqriYAzX7Y/h/byN1CNS3rUAW7s0ZO8GsPJYB2aTAi3bwYaGqzi3ZIGaXVzqHTr0A+Zy2RABGtQP0+SFpC1U6Z4Ivr036peoG0+MnHYEXmkdQLa+EXee7MRcSiAXEmOxG3j8xCm1bPlq78DBw5jP55sJMDLRYI/UJTuQkqTw08mg5xP1vchWNvgMn/YwlUqqnp5eNb9ssVdTewZHRoZrCHA/AUoBHvXp+Q+C6p0DMRxMiv8AiSGZ+KWtITxw4ZbPPqHpmMlk1Fdf78XZxaWFtrbrePv2yBYmJcwjM5P/Il2aYpsCauP3BChRN0w0DANN00Tg3BeCnunb0NCgqq9vUHNLyse3Vu5A27Yf6HpiCqOtYZg8RCwutPSPsTVXccPBmMo4gNKyMJ1Oo+s6aNsWOo6NyWQSU6mUunjxklq+4nUsnlP2uKkpgNns0MnR0fvMX0GdcWPGYFL8/Wuon8bL2368HW9nU+rS5Svqm2/3IXmso6MTYzfa1cVLl9Xu3XtU+YKlOPOVOWPV1ccxnU7dbQ4GJgvBmT/QlgSamArqV3uPjvGEMZZ0XbWzahcdUouXrFArVr6hXlu2SpXOX0TfxktKFz4+Wn0MHcf5xzSN8mTSJXKTmKYZNMtFUvig620JDy0pMd7fj6tWv1Ugj82ZW+bNKi71ZhfP9xYuWlao3FaFjY0BnxmBpdMp5jhOkZQW8zdtWnImBRTZElhfwpzKAeoA+KOWlmt4+nQNkmkPHz6CtWfOYiTSipZlPchlsyebmwOTiRmBua7r68FsW7CzZ68zWo5UPvWBc2C2LaeNjAxXDefz52gCcrlscHg4X0PWIDVJAMqlMokZgdH2/xceF02SwJ+/igAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "TuringTrader.com, Mach-2: A mean-variance optimization strategy with 2x leverage",
                "title": "TuringTrader.com, Mach-2: A mean-variance optimization strategy with 2x leverage",
                "src": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
                "srcSet": ["/static/2b23f891a5d398e021f35a8c1073fc00/5a46d/logo-512x512.png 300w", "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png 512w"],
                "sizes": "(max-width: 512px) 100vw, 512px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><strong parentName="p">{` Learn More `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/leveraged-etf-portfolios/"
            }}>{`Leveraged ETF Portfolios`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/market-vane/"
            }}>{`Market Vane Indicator`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/market-liquidity/"
            }}>{`Market Liquidity`}</a></li>
        </ul>
      </Aside>
      <p>{`The operation of `}<em parentName="p">{`Mach-2`}</em>{` can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`in bull-markets, trade ETFs tracking the S&P 500, Nasdaq-100, Dow-Jones Industrial Average, Russell 2000, S&P MidCap 400, and S&P SmallCap 600 indices`}</li>
        <li parentName="ul">{`in bear-markets, trade ETFs tracking U.S. Treasuries with 7-10 year and 20+ year maturity, and gold `}</li>
        <li parentName="ul">{`switch between bull- and bear-market regimes based on `}<em parentName="li">{`TuringTrader's Market Vane`}</em>{` signal`}</li>
        <li parentName="ul">{`evaluate the performance of various asset weightings over a lookback period of approximately three months`}</li>
        <li parentName="ul">{`rebalance every week, picking the combination with the best modified Sharpe Ratio`}</li>
      </ul>
      <p><em parentName="p">{`Mach-2`}</em>{` is closely related to LogicalInvest's `}<em parentName="p">{`Universal Investment Strategy`}</em>{`. We enhanced the strategy by adding more assets and switching between separate bull- and bear-market strategies.`}</p>
      <h2>{`Diversification`}</h2>
      <p><em parentName="p">{`Mach-2`}</em>{` fully invests in one or more broad indices tracking the U.S. stock market in bullish markets. During bear markets, the portfolio switches to the safety of U.S. treasuries and gold. With these properties, `}<em parentName="p">{`Mach-2`}</em>{` is well diversified in terms of company risk but relies on `}<em parentName="p">{`serial diversification`}</em>{` to avoid steep losses.`}</p>
      <p>{`Our simulation shows a beta of less than 0.75, which is 60% lower than the portfolio's 2x leverage and indicating successful tactical asset rotation. Nonetheless, investments in `}<em parentName="p">{`Mach-2`}</em>{` have a tail-risk equivalent to being 2x leveraged on the S&P 500.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p><em parentName="p">{`Mach-2`}</em>{` beats the S&P 500 benchmark in most years with only brief periods of underperformance. The mean-variance optimization helps to manage portfolio volatility, while the market-regime filter helps to avoid deep drawdowns.`}</p>
      <p>{`As a result, `}<em parentName="p">{`Mach-2`}</em>{`'s portfolio volatility is 40% lower than the value expected based on the 2x leverage. Further, the portfolio's downside is about 70% lower than buying and holding a 2x leveraged S&P 500.`}</p>
      <p>{`In summary, `}<em parentName="p">{`Mach-2`}</em>{` delivers most of the desirable upside of geared ETFs while successfully limiting risks to levels bearable for retail investors.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p><em parentName="p">{`Mach-2`}</em>{` is a very aggressive strategy. Investors should not take leverage lightly and consider the tail risk when seeking high returns. In our opinion, `}<em parentName="p">{`Mach-2`}</em>{` is best used in the context of meta-portfolios, namely `}<a parentName="p" {...{
          "href": "/portfolios/tt-all-stars-lev/"
        }}>{`All-Stars Leveraged`}</a>{`.`}</p>
      <p><em parentName="p">{`Mach-2`}</em>{` trades frequently and regularly triggers taxable events. The portfolio rarely holds assets long enough to qualify for long-term treatment of capital gains. However, because of the portfolio's massive upside over buy-and-hold, `}<em parentName="p">{`Mach-2`}</em>{` may still add value to taxable accounts.`}</p>
      <p><em parentName="p">{`Mach-2`}</em>{` makes use of 2x leveraged ETFs, which are considered high-risk instruments. Many brokerages require signing additional disclosures before allowing investors to use these instruments in their accounts. Because we use these products in tandem with our proven market-regime indicator, we believe our use of these instruments to be responsible. The ETFs used often trade at relatively thin volumes. Read our `}<a parentName="p" {...{
          "href": "/articles/market-liquidity/"
        }}>{`background article on market liquidity`}</a>{` to learn why we believe this to be acceptable.`}</p>
      <p><em parentName="p">{`Mach-2`}</em>{` invests in no more than three ETFs at a time. The strategy should function as intended with as little as $5,000 of capital.`}</p>
    </Review>
    <Notes mdxType="Notes">
      <p><strong parentName="p">{`Portfolio Revisions`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, April 2021`}</a>{`: Initial release, based on `}<a parentName="li" {...{
            "href": "/portfolios/li-universal-investment-strategy/"
          }}>{`LI's Universal Investment Strategy`}</a>{`.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=2"
          }}>{`v2, April 2022`}</a>{`: Reduced volatility in regimes of mostly negative returns.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=2"
          }}>{`v2, May 2022`}</a>{`: Replaced UGL with DGP.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=3"
          }}>{`v3, October 2022`}</a>{`: Implement exit to T-bills when mean-variance optimization fails.`}</li>
      </ul>
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      